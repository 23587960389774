import React from "react"
import { useState } from "react"
import Navbar from "../../components/Navbar"
// import Notification from "./Notification"
import Profile from "./Profile"
// import { useSelector } from "react-redux";
import Notification from "./Notification"

const Account = () => {
    const [currentPage, setCurrentPage] = useState("profile")
    const mood = "light"
    // const { mood } = useSelector((state) => state.mood);
    // const { user } = useSelector((state) => state.userAuth);

    return (
        <main className={`${mood === "light" ? ` border-[#E4E4E7]` : "bg-[#161A25] border-gray"}`}>
            <Navbar />
            <div className="py-6 w-full max-w-6xl mx-auto">
                <div className="mx-auto">
                    <h1 className="text-[24px] font-bold text-[#18181B]">My Account</h1>
                </div>
                <>
                    <div className="mx-auto mt-8">
                        <div className="w-full overflow-x-auto overflow-y-hidden scrollbar border-b border-[#E4E4E7]">
                            <div className="">
                                <nav className="flex -mb-px space-x-10 w-full">
                                    <p
                                        className={`
                    ${
                        currentPage === "profile"
                            ? "!text-[#4F46E5] !border-[#4F46E5]"
                            : "border-transparent hover:border-[#4F46E5] text-[#52525B]"
                    } py-2  text-base font-medium transition-all duration-200 border-b-[3px] whitespace-nowrap`}
                                        onClick={() => setCurrentPage("profile")}
                                    >
                                        Profile
                                    </p>
                                    <p
                                        className={`
                    ${
                        currentPage === "Notification"
                            ? " text-[#4F46E5] border-[#4F46E5]"
                            : "border-transparent hover:border-[#4F46E5] text-[#52525B]"
                    } py-2 text-base font-medium transition-all duration-200 border-b-[3px]  whitespace-nowrap`}
                                        onClick={() => setCurrentPage("Notification")}
                                    >
                                        Notification
                                    </p>
                                </nav>
                            </div>
                        </div>
                    </div>
                </>
            </div>
            <>
                {currentPage === "profile" && <Profile />}
                {currentPage === "Notification" && <Notification />}
            </>
        </main>
    )
}

export default Account
