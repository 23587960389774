/* eslint-disable */
import axios from "../../axios";
import React, { useState } from "react";
import ProfileUpload from "../../components/profielUpload/ProfileUpload";
import { useDispatch } from "react-redux";
import { setUserValue } from "../../redux/slices/auth";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import EventName from "./../../components/popUp/EventName";

const Profile = () => {
  const [profileDetails, setProfileDetails] = useState({
    name: "",
  });
  const [popUp, setPopUp] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userAuth);
  const mood = "light";
  const userToken = localStorage.getItem("Admin_Token");

  const handelChange = (e) => {
    const { name, value } = e.target;
    setProfileDetails((pre) => {
      return { ...pre, [name]: value };
    });
  };
  const removeLogo = async () => {
    if (user?.profilePic.length > 0) {
      const { data } = await axios.post("/delete-profile", {
        id: user?.id,
      });
      if (data.success) {
        if (userToken !== null) {
          const datas = await axios.get("/superAdmin-Details", {
            headers: {
              Authorization: userToken,
            },
          });
          dispatch(setUserValue(datas.data.data[0]));
          toast.success(`${data.message}`);
          return;
        }
        return;
      }
    } else {
      return toast.error("There is no profile picture available.");
    }
  };

  const update = async () => {
    setShowPopup(true);
    const { data } = await axios.post("/update-superAdmin", {
      id: user?.id,
      name: profileDetails.name,
    });
    if (data.success) {
      if (userToken !== null) {
        const datas = await axios.get("/superAdmin-Details", {
          headers: {
            Authorization: userToken,
          },
        });
        dispatch(setUserValue(datas.data.data[0]));
        toast.success(`${data.message}`);
        return;
      }
    }
  };

  return (
    <div className="w-full max-w-6xl py-3 mx-auto">
      <div className="mt-6">
        <p
          className={`text-[17px] ${
            mood === "light" ? "text-black" : "text-[#ddd]"
          }  font-bold text-gray-900`}
        >
          Profile
        </p>
        <p className="mt-1 text-sm font-medium text-[#52525B]">
          You can edit your profile here
        </p>
      </div>

      <div className="max-w-3xl mt-12">
        <div className="space-y-8">
          <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
            <label
              className={`block text-[14px] font-bold ${
                mood === "light" ? "text-black" : "text-[#ddd]"
              } sm:mt-px sm:pt-2`}
            >
              Profile Photo
            </label>
            <div className="mt-2 sm:mt-0 sm:col-span-2">
              <div className="flex items-center space-x-6">
                <img
                  className="flex-shrink-0 object-cover w-12 h-12 rounded-lg"
                  src={
                    user?.profilePic !== ""
                      ? user?.profilePic
                      : "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
                  }
                  alt=""
                />
                <button
                  onClick={() => setPopUp(true)}
                  type="button"
                  className={`text-sm font-semibold text-[#4F46E5] transition-all duration-200  rounded-md hover:text-indigo-700 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 ${
                    mood === "light" ? "text-blue" : "text-blue bg-transparent"
                  }`}
                >
                  Update
                </button>
                {user?.profilePic !== "" && (
                  <button
                    type="button"
                    className={`text-sm font-semibold text-black-600 transition-all duration-200  rounded-md hover:text-indigo-700
                       focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 ${
                         mood === "light"
                           ? "text-red"
                           : "bg-transparent text-red"
                       }`}
                    onClick={removeLogo}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
            <label
              className={`block text-[14px] font-bold ${
                mood === "light" ? "text-black" : "text-[#ddd]"
              }  sm:mt-px sm:pt-2`}
            >
              Full Name
            </label>
            <div className="mt-2 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                name="name"
                id=""
                placeholder="Name"
                onChange={handelChange}
                defaultValue={user?.name}
                className="!outline-none border block w-full px-4 py-3 placeholder-[#52525B] border-[#D4D4D8] rounded-lg focus:ring-
                [#4F46E5] focus:border-[#4F46E5] sm:text-sm caret-[#4F46E5]"
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
            <label
              className={`block text-[14px] font-bold ${
                mood === "light" ? "text-black" : "text-[#ddd]"
              }  sm:mt-px sm:pt-2`}
            >
              Email Address
            </label>
            <div className="mt-2 sm:mt-0 sm:col-span-2">
              <input
                type="email"
                name="email"
                id=""
                placeholder=""
                disabled
                className="!outline-none border block w-full px-4 py-3 placeholder-[#52525B] border-[#D4D4D8] rounded-lg focus:ring-
                [#4F46E5] focus:border-[#4F46E5] sm:text-sm caret-[#4F46E5]"
                defaultValue={user?.email}
              />
            </div>
          </div>
        </div>

        <div className="mt-6 sm:mt-12 flex justify-start mb-3">
          <button
            type="submit"
            onClick={update}
            className="active:scale-95 hover:scale-[1.02] inline-flex items-center justify-center px-10 py-3 text-sm font-semibold 
            leading-5 text-white transition-all duration-200 bg-[#4F46E6] border border-transparent rounded-md focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-igray-700 hover:bg-[#4e46e6c0]"
          >
            Save
          </button>
        </div>
      </div>
      {popUp && (
        <>
          <ProfileUpload show={setPopUp} />
        </>
      )}
      {showPopup && (
        <EventName
          status="updated"
          show={showPopup}
          hide={setShowPopup}
          message="profile"
        />
      )}
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Profile;
