/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Avatar from "react-avatar-edit";
import axios from "../../axios";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { setUserValue } from "../../redux/slices/auth";

const ProfileUpload = ({ show }) => {
  const [preview, setPreview] = useState(null);
  const [imageCode, setImageCode] = useState(null);
  const { user } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const userToken = localStorage.getItem("Admin_Token");

  const onClose = () => {
    setPreview(null);
  };

  const UploadImage = async () => {
    if (preview !== null) {
      const { data } = await axios.post("/upload-profile", {
        id: user?.id,
        image: preview,
      });
      if (data.success) {
        if (userToken !== null) {
          const datas = await axios.get("/superAdmin-Details", {
            headers: {
              Authorization: userToken,
            },
          });
          dispatch(setUserValue(datas.data.data[0]));
          toast.success(data.message);
          show(false);
          return;
        }
      }
    } else {
      return toast.error("Insert Image");
    }
  };

  const onCrop = (view) => {
    setPreview(view);
  };

  return (
    <div
      id="popup-modal"
      tabindex="-1"
      className=" fixed top-10 h-full w-screen  flex flex-1 justify-center items-center "
      aria-hidden="true"
    >
      <div className="relative p-4 w-[350px] py-4 max-w-md h-full md:h-auto right-52">
        <div className="relative bg-white rounded-lg shadow-2xl ">
          <button
            type="button"
            className="absolute -top-2 -right-1 flex items-center justify-center text-white bg-red hover:bg-gray hover:text-white rounded-full text-sm p-1 ml-auto  transition-all duration-300 w-[25px] h-[25px] text-center"
            data-modal-toggle="popup-modal"
            onClick={() => show(false)}
          >
            x
          </button>
          <div className="p-1 text-center flex items-center flex-col">
            <div className="my-3 flex items-center  bg-white rounded px-3 overflow-hidden">
              <Avatar
                width={270}
                height={270}
                onCrop={onCrop}
                onClose={onClose}
                src={imageCode}
              />
            </div>
            <div className="w-full  text-end py-3">
              <button
                className="bg-blue text-white px-4 py-[2px] rounded-full mx-6 hover:bg-transparent hover:text-blue"
                onClick={UploadImage}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default ProfileUpload;
